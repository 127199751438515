import React, {Component} from 'react';
import {getLocationLabel} from './locations';
import {calculateDistance, compareGeolocation} from './geolocation';
import joinReact from './join_react';
import {RecruitmentStatus} from '../constants.js.erb';

export default class GeolocationInformation extends Component {
  constructor(props) {
    super(props);
    if (props.cache) {
      let {data, unmapped_found} = props.cache;
      this.state = {data, unmapped_found};
    } else {
      let {data, unmapped_found} = this.calculateDistances();
      this.state = {data, unmapped_found};
    }
  }

  // TODO: This method is duplicated as search_populations.js
  calculateDistances() {
    let {patient, trial_locations, extra_locations} = this.props;
    let locations = [];

    if (patient && patient.locations.length > 0) {
      locations = locations.concat(patient.locations);
    }

    if (extra_locations && extra_locations.length > 0) {
      locations = locations.concat(extra_locations);
    }

    if (locations.length === 0) return [];

    let unmapped_found = false;
    let data = [];

    for (let location of locations) {
      if (location.lon && location.lat) {
        for (let trial_location of trial_locations) {
          let found = false;
          for (let cancer_center of trial_location.cancer_centers) {
            if (cancer_center.lon && cancer_center.lat) {
              found = true;
              let distance = Math.round(calculateDistance(location, cancer_center));
              data.push({type: 'cancer_center', location, trial_location, cancer_center, distance});
            }
          }

          if (!found) {
            data.push({type: 'trial_location', trial_location, location});
            unmapped_found = true;
          }
        }
      }
    }

    data = data.sort(compareGeolocation);
    return {data, unmapped_found};
  }

  render() {
    let {data} = this.state;

    if (!data || data.length === 0) return null;
    let unique_location_ids = [...new Set(data.map(c => c.location.id))];

    return (
      <div className="result-geo-info">
        {unique_location_ids.map(location_id => {
          let matching_locations = data.filter(c => c.location.id === location_id);
          let first_cached_cancer_center = matching_locations.find(c => c.type === 'cancer_center' && c.trial_location.status === 'RECRUITING');

          return <CancerCenterInformation key={location_id} {...first_cached_cancer_center} all_locations={matching_locations}/>
        })}
      </div>
    );
  }
}



class CancerCenterInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }


  toggleOtherLocations() {
    let show_other_locations = !this.state.show_other_locations;
    this.setState({show_other_locations});
  }

  renderPeople(people) {
    if (!people || people.length === 0) return null;

      let links = people.map(person => {
        let link = `/admin/persons/${person.id}`;
        return <a href={link} target="_blank">{person.full_name}</a>
      });

    return (
      <div>
        <i className="fa fa-user"></i> {joinReact(links, ', ')}
      </div>
    )
  }

  renderLocations(locations) {
    return (
        <table className="table table-condensed">
          <tbody>
            {locations.map(({cancer_center, trial_location, location, distance}) => {
              let link_to_trial_location = `/admin/trials/${trial_location.trial_id}/locations/${trial_location.id}/edit`;

              let key = cancer_center.id + '::' + trial_location.id;
              return (
                <tr key={key}>
                  <td>
                    <a href={link_to_trial_location} target="_blank" className="panel-center-link">{cancer_center.name}</a>
                    {this.renderPeople(trial_location.people)}
                  </td>
                  <td style={{ textAlign: 'right' }}>{distance} miles</td>
                </tr>
              )
            })}
          </tbody>
        </table>
    )
  }

  renderNotGeolocatedLocations() {
    let {all_locations} = this.props;
    let not_geolocated = all_locations.filter(c => c.type === 'trial_location');
    if (not_geolocated.length === 0) return null;

    return (
      <div>
        <h4>Can not be geolocated</h4>
        <table className="table table-condensed">
          <tbody>
            {not_geolocated.map(({trial_location}) => {
              let link_to_location = `/admin/trials/${trial_location.trial_id}/locations/${trial_location.id}/edit`;
              return (
                <tr key={trial_location.id}>
                <td><a href={link_to_location} target="_blank">{trial_location.name}</a></td>
                </tr>
              )
            })}
          </tbody>
        </table>

      </div>

    )
  }
  renderAllLocations() {
    let {show_other_locations} = this.state;
    let {all_locations} = this.props;
    if (!show_other_locations) return null;

    return (
      <div>
        {Object.keys(RecruitmentStatus).map(recruitment_status => {
          let locations = all_locations.filter(c => c.type === 'cancer_center' && (c.trial_location.status || c.trial_location.trial_status) === recruitment_status);
          if (locations.length === 0) return null;
          let status_humanized = RecruitmentStatus[recruitment_status];

          return (
            <div key={recruitment_status}>
              <h4 className="locations_group_header">{status_humanized}</h4>
              {this.renderLocations(locations)}
            </div>
          )
        })}

        {this.renderNotGeolocatedLocations()}
      </div>
    );
  }


  renderNoRecruitingCancerCenterFound() {
    return (
      <div>
        No Recruiting Locations
      </div>
    );
  }

  renderMainCancerCenter() {
    let {cancer_center, trial_location, distance, location} = this.props;
    if (!cancer_center) return this.renderNoRecruitingCancerCenterFound();
    let label = locationLabel(location, {show_zip: false, show_status: false});
    let location_el;
    if (location.primary) {
      location_el = <span className="main-location" title="Main Location">{label}</span>
    } else if (location.place_id) {
      location_el = <span className="custom-location" title="Custom Location">{label}</span>
    } else {
      location_el = <span className="other-location">{label}</span>
    }
    let link_to_trial_location = `/admin/trials/${trial_location.trial_id}/locations/${trial_location.id}/edit`;

    return (
      <div>
        <p>
          {location_el} is <strong>{distance} miles</strong> to the closest recruiting location: <a href={link_to_trial_location} target="_blank" className="panel-center-link">{cancer_center.name}</a>
        </p>
          {this.renderPeople(trial_location.people)}
      </div>
    )
  }

  render() {
    let {cancer_center, all_locations} = this.props;
    let {show_other_locations} = this.state;

    let action_btn;

    if (all_locations.length > 1) {
      let label = show_other_locations ? 'Hide' : 'Show';
      action_btn = <button className="btn btn-default" onClick={e => this.toggleOtherLocations()}>{label} {all_locations.length} locations</button>
    }

    return (
      <div className="cancer_center_information">
        {this.renderMainCancerCenter()}
        {action_btn}
        {this.renderAllLocations()}
      </div>
    );
  }
}

function locationLabel(location) {
  return location.display_name || getLocationLabel(location, {show_zip: false, show_status: false});
}
