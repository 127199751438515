import React, {Component} from 'react';
import Form from './form';
import request from '../request';
import DiagnosticAndGeneOptions from './dignostic_and_gene_options';
import {MoaTypeSearchKey, MoaFamilySearchKey, CancerTypeSearchKey} from '../constants.js.erb';

export default class GeneForm extends Component {
  constructor(props) {
    super(props);
    let {gene} = props;
    this.state = {gene};
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(gene) {
    this.setState({gene});
  }


  onSubmit(e) {
    e.preventDefault();
    this.setState({submitting: true});

    let {gene} = this.state;
    let url = '/admin/genes/';
    let method;
    if (gene.id) {
      url += '/' + gene.id;
      method = 'PUT';
    } else {
      method = 'POST'
    }

    request(method, url, {gene: gene}).then(gene => {
      this.setState({gene: gene, errors: [], submitting: false});
    }).catch(({errors}) => {
      console.log("ERROR", errors);
      this.setState({errors, submitting: false});
    });
  }

  formFields() {
    let {connected_populations} = this.props;
    return [
      {name: 'name', required: true},
      {name: 'aliases'},
      {name: 'cancer_type_ids', label: 'Cancer Types', type: 'slektr', multiple: true, src: "/admin/cancer_types/as_options.json", search_key: CancerTypeSearchKey, link: '/admin/cancer_types/$ID/edit'},
      {name: 'moa_type_ids', label: 'Moa Types', multiple: true, type: 'slektr', src: "/admin/moa_types/as_options.json", search_key: MoaTypeSearchKey, link: '/admin/moa_types/$ID/edit'},
      {name: 'record_family_ids', label: 'Moa Families', multiple: true, type: 'slektr', src: "/admin/moa_families/as_options.json", search_key: MoaFamilySearchKey, link: '/admin/moa_families/$ID/edit'},
      {name: 'data', label: 'Options', type: GenesOptions, connected_populations: connected_populations}
    ];
  }

  render() {
    let {gene, errors, submitting} = this.state;
    return (
      <div className="gene_form_holder">
        <Form prefix="gene" model_name="Gene" model={gene} fields={this.formFields()} onChange={this.onChange} onSubmit={this.onSubmit} errors={errors} submitting={submitting}/>
      </div>
    );
  }
}

class GenesOptions extends DiagnosticAndGeneOptions {

  findConnectedPopulations(gene_option) {
    let {model, field} = this.props;
    let {connected_populations} = field;
    if (!connected_populations) return [];
    return connected_populations.filter(population => {
      let data = population.data.genes_markers;
      for (let section of ['required', 'prohibited']) {
        if (data[section]) {
          for (let group of data[section]) {
            if (group.collection) {
              for (let record of group.collection) {

                if (record.diagnostics_and_gene_id == model.id && record.gene_options && record.gene_options.findIndex(r => r === gene_option.id) !== -1) {
                  return true;
                }
              }
            }
          }
        }
      }
      return false;
    });
  }


  findPopulationsConnectedToAny() {
      let {model, field} = this.props;
    let {connected_populations} = field;
    if (!connected_populations) return [];
    return connected_populations.filter(population => {
      let data = population.data.genes_markers;
      for (let section of ['required', 'prohibited']) {
        if (data[section]) {
          for (let group of data[section]) {
            if (group.collection) {
              for (let record of group.collection) {

                if (record.diagnostics_and_gene_id == model.id && (!record.gene_options || record.gene_options.length === 0)) {
                  return true;
                }
              }
            }
          }
        }
      }
      return false;
    });

  }


  renderHeader() {
    return (
      <div>
        <h3>Options</h3>
      </div>
    )
  }

  renderFormHeader() {
    let {current_value} = this.state;
    let title = current_value.id ? 'Edit Option' : 'Add New Option';
    return <h4><b>{title}</b></h4>;
  }

  renderAny() {
    let gene_any = {id: 'any', name: 'Any'};
    let populations = this.findPopulationsConnectedToAny();
    let populations_btn;
    if (populations.length > 0) {
      populations_btn = <button className="btn btn-link" onClick={e => this.showModal(gene_any, populations)}>Tagged in {populations.length} populations</button>
    }

    return (
      <tr>
        <td><strong><i>Any Alteration</i></strong></td>
        <td>{populations_btn}</td>
        <td>
        </td>
      </tr>
    );
  }
}
