var buildHeaders = (opt) => {
  let selector = document.querySelector('meta[name="csrf-token"]');
  let token = selector && selector.content;
  let headers = {
    'Accept': 'application/json',
    'X-CSRF-TOKEN': token
  }

  if (!opt.multipart) {
    headers['Content-Type'] = 'application/json';
  }

  if (opt.heders) {
    headers = {...headers, ...opt.headers};
  }

  return headers;
}

const request = (method, url, data, opt={}) => {
  let body;
  if (data) {
    if (opt.multipart) {
      body = new FormData();
      for (let key of Object.keys(data)) {
        let full_key = opt.root ? opt.root + '[' + key + ']' : key;
        body.append(full_key, data[key]);
      }
    } else {
      body = JSON.stringify(data);
    }
  }


  let p = new Promise((done, reject) => {
    let headers = buildHeaders(opt);
    fetch(url, {method: method, body, headers, credentials: 'include'})
      .then((response) => {
        if(response.ok) {
          if (opt.blob) {
            console.log("downloading blob");
            response.blob().then(raw => done(raw));
          } else {
            response.json().then((data) => done(data));
          }
        } else {
          response.json().then((data) => {
            data._response = response;
            reject(data)
          }).catch(error => {
            error._response = response;
            reject(error);
          });
        }

        // Create event to reset the signout_reminder
        let event = new Event('signout_reminder_reset');
        window.dispatchEvent(event);
      })
  });
  return p;
};


export default request;
