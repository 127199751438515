import React, {Component} from 'react';
import Form from '../form';
import SavePanel from './save_panel';
import NotesWidget from './notes_widget';
import request from '../../request';
import {GeneSearchKey} from '../../constants.js.erb';
import * as Inflector from 'inflected';

export default class GenesMarkers extends Component {
  constructor(props) {
    super(props);
    let {population} = props;
    let genes_markers = setupDefaults(population.data);
    let data = buildFakeData(genes_markers);
    let {notes} = genes_markers;
    this.state = {data, genes_markers, notes};

    this.submit = this.submit.bind(this);
    this.onChangeNotes = this.onChangeNotes.bind(this);
  }

  componentDidMount() {
    window.addEventListener('population-save-request', this.submit, false);
  }

  componentWillUnmount() {
    window.removeEventListener('population-save-event', this.submit, false);
  }


  submit() {
    this.setState({submitting: true})
    let {population, onChange} = this.props;
    let {data, notes} = this.state;
    let genes_markers = buildFromFake(data);

    // Notes are stored apart
    genes_markers.notes = notes;

    population.data.genes_markers = genes_markers;
    onChange(population.data);
  }


  onChange(section, index, group) {
    let {data} = this.state;
    data[section][index] = group;
    this.setState({data}, this.submit);
  }

  onChangeNotes({notes}) {
    this.setState({notes}, this.submit);
  }

  addGroup(section) {
    let {data} = this.state;
    data[section].push(defaultGroup());
    this.setState({data});
  }

  deleteGroup(section, index) {
    if (!confirm('Are you sure?')) return;
    let {data} = this.state;
    data[section].splice(index, 1);
    this.setState({data}, this.submit);
  }

  renderForm(section, index, group) {
    let {reseteable} = this.props;
    let select2_opt = {type: 'slektr', multiple: true, src: "/admin/diagnostics_and_genes/as_extended_options.json", search_key: GeneSearchKey};
    let validation_options = section === 'required' ? REQUIRED_OPTIONS : PROHIBITED_OPTIONS;

    let fields = [
      {name: 'collection', label: 'Genes and Diagnostics', ...select2_opt},
      {name: 'validation', type: 'radio', reseteable, collection: validation_options},
    ];

    return (
      <div>
        <Form model={group} fields={fields} onChange={group => this.onChange(section, index, group)} />
      </div>
    );
  }

  renderSections() {
    let {data} = this.state;
    return SECTIONS.map(section => {
      let title = Inflector.titleize(section);
      let section_data = data[section];
      return (
        <div key={section} className="col-md-6 genes-groups-outer-holder">
          <h3>{title}</h3>
          <div className="scenario_holder">
            {section_data.map((group, index) => {
              return (
                <div key={group.id} className="field-group-holder">
                  <button className="btn btn-danger btn-sm pull-right" onClick={e => this.deleteGroup(section, index)}>
                    <i className="fa fa-trash"></i>
                  </button>
                  <h4>Scenario {index + 1}</h4>
                  {this.renderForm(section, index, group)}
                </div>
              );
            })}
            <button className="btn btn-default btn-sm" onClick={e => this.addGroup(section)}>Add Scenario</button>
          </div>
        </div>
      )
    });
  }

  render() {
    let {notes} = this.state;
    return (
      <div id="genes_markers">
        <a name="tab-top-section"></a>
        <div className="well label-spacing select-100">

          <div className="intervention_selection_holder prior_selections">
            <div className="tab-section-holder">
              <div>
                <h3><b>Genes and Diagnostics</b></h3>
                <p className="population_subtitle">Most populations will not require multiple scenarios. Keep in mind required and prohibited will be evaluated independently.</p>
              </div>
              <a href="#tab-notes-section" className="btn btn-primary btn-xs">Jump to Notes</a>
            </div>
            <div className="row">
             {this.renderSections()}
            </div>
            <hr />
            <a name="tab-notes-section"></a>
            <div className="tab-section-holder">
              <NotesWidget data={{notes}} onChange={this.onChangeNotes} />
              <a href="#tab-top-section" className="btn btn-primary btn-xs">Jump to Top</a>
            </div>
          </div>
        </div>
      </div>


    );
  }

}

const SECTIONS = ["required", "prohibited"];

function defaultGroup() {
  return {id: generateUUID(), collection: [], validation: 'some'};
}

function setupDefaults(data) {
  let P = data.genes_markers || {};
  for (let section of SECTIONS) {
    if (!data.genes_markers[section]) data.genes_markers[section] = [defaultGroup()];
    for (let group of data.genes_markers[section]) {
      if (!group.collection) group.collection = [];
    }
  }

  if (!P.notes) P.notes = [];
  return P;
}

function buildFakeData(genes_markers) {
  let fake = {}
  for (let section of SECTIONS) {
    fake[section] = genes_markers[section].map(original_group => {
      let group = Object.assign({}, original_group);
      group.collection = buildFakeItems(original_group.collection);
      return group;
    });
  }
  return fake;
}

function buildFakeItems(data) {
  let fake = [];
  for (let item of data) {
    if (item.any) {
      fake.push("" + item.diagnostics_and_gene_id)
    }

    if(item.gene_options && item.gene_options.length > 0) {
      for (let opt of item.gene_options) {
        fake.push(`${item.diagnostics_and_gene_id}-${opt}`)
      }
    }

    if(item.results) {
      for (let result of item.results) {
        fake.push(`${item.diagnostics_and_gene_id}-R:${result}`);
      }
    }

  }
  return fake;
}

const GENE_REGEXP = /(\d+)-([\d\w_-]+)/
const DIAGNOSTIC_REGEXP = /(\d+)-R\:([\d\w_-]+)/


function buildFromFake(data) {
  let genes_markers = {}
  for (let section of SECTIONS) {
    genes_markers[section] = data[section].map(original_group => {
      let group = Object.assign({}, original_group);
      group.collection = buildFromFakeItems(original_group.collection);
      return group;
    });
  }
  return genes_markers;
}

function buildFromFakeItems(fake) {
  let data = [];
  for (let fake_item of fake) {
    let match, id, result, field;

    if (DIAGNOSTIC_REGEXP.test(fake_item)) {
      match = DIAGNOSTIC_REGEXP.exec(fake_item);
      id = +match[1];
      field = undefined;
      result = match[2];
    }
    else if (GENE_REGEXP.test(fake_item)) {
      match = GENE_REGEXP.exec(fake_item);
      id = +match[1];
      field = match[2];
      result = undefined;
    }
    else {
      id = +fake_item;
      field = undefined;
      result = undefined;
    }


    let item = data.find(d => d.diagnostics_and_gene_id === id);
    if (!item) {
      item = {diagnostics_and_gene_id: id}
      data.push(item);
    }

    if (result) {
      if (!item.results) item.results = [];
      item.results.push(result);
    } else if (field) {
      if (!item.gene_options) item.gene_options = []
      item.gene_options.push(field);
    } else {
      item.any = true;
    }
  }

  return data;
}

const REQUIRED_OPTIONS = {
  all: 'Required to Have All',
  some: 'Required to Have At Least One'
}

const PROHIBITED_OPTIONS = {
  all: 'Prohibited From Having All (Can Have Some)',
  some: 'Prohibited From Having Any'
}
