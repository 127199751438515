import React, {Component} from 'react';
import request from '../request';
// import Slektr from '../slektr';
import Slektr from 'slektr';
import 'slektr/dist/slektr.css';

export default class SlektrWrapper extends Component {

  onChange(option) {
    let {name, onChange, onChangeCallback} = this.props;
    let meta = {value_label: option.label};
    onChange({target: {name, value: option.value, el: this.el}}, meta);
    onChangeCallback &&  onChangeCallback(option);
  }

  componentDidUpdate(prevProps) {
    if (Array.isArray(prevProps.value) && Array.isArray(this.props.value) && arrayEquals(prevProps.value, this.props.value)) return;
    else if (prevProps.value === this.props.value) return;

    let {value} = this.props;
    this.slektr.setValue(value, true);
  }

  initEl(el) {
    if (!el || el.dataset.slektr) return;
    this.el = el;

    let {editable, remote, multiple, src, searchKey, value, tags, allowBlank, placeholder, link, renderOptionCallback, renderValueCallback, sortOptions } = this.props;
    let options = migrateOptions(this.props.options);
    let config = {value, multiple, allowBlank, placeholder, options, renderOptionCallback, renderValueCallback, sortOptions};
    config.onChangeCallback = o => this.onChange(o);

    if (src && src.length > 0) {
      config.remoteOptionsSrc = src;
      config.remoteOptionsSearchName = searchKey;
      config.initOptionsCallback = initCallback;
      config.searchOptionsCallback = searchCallback;
    }

    if (link && link.length > 0) {
      config.linkTemplate = link;
      config.renderValueCallback = renderLink;
    }

    this.slektr = new Slektr(el, config);
  }

  render() {
    let {className} = this.props;
    return <select ref={el => this.initEl(el)} className={className} ></select>
  }

}


function initCallback(slektr) {
  if (slektr.config.remoteOptionsSrc && slektr.config.remoteOptionsSrc.length > 0) {
    return new Promise(function(resolve) {
      var url = slektr.config.remoteOptionsSrc;

      if (slektr.config.multiple && slektr.value.length > 0) {
        url = url + '?ids=' + slektr.value.join(',')
      } else {
        url = url + '?ids=' + slektr.value;
      }

      fetch(url).then(function(response) {
        if(response.ok) {
          response.json().then(({results}) => {
            let data = results.map(r => ({value: r.id, label: r.text}));

            // if sort options is configured
            if (slektr.config.multiple && slektr.value.length > 0 && slektr.config.sortOptions) {
              data = data.sort(function (a, b) {
                return ('' + a.label).localeCompare(b.label);
              });

              // Replace the value to force the order
              if (slektr.config.multiple && slektr.value.length > 0) {
                slektr.value = data.map(o => o.value);
              }
            }

            resolve(data);

          });
        }
      });
    });
  }

  // Return a dummy promise otherwise
  return Promise.resolve();
}

function searchCallback(text, slektr) {
  if (slektr.config.remoteOptionsSrc && slektr.config.remoteOptionsSrc.length > 0) {
    return new Promise(function(resolve) {
      let query_param = slektr.config.remoteOptionsSearchName || 'query';
      var url = `${slektr.config.remoteOptionsSrc}?q[${query_param}]=${text}`;
      url = encodeURI(url);

      fetch(url).then(function(response) {
        if(response.ok) {
          response.json().then(({results}) => {
            let data = results.map(r => (Object.assign(r, {value: r.id, label: r.text})))
            resolve(data)
          });
        }
      });
    });
  }

  // Return a dummy promise otherwise
  return Promise.resolve();
}

function renderLink(option, slektr) {
  let link =   slektr.config.linkTemplate.replace('$ID', option.value);
  return `<a href="${link}">${option.label}</a>`;
}

function arrayEquals(a, b) {
  return a.length === b.length && a.every((val, index) => val === b[index]);
}

function migrateOptions(options) {
  return options.map(o => {
    let opt = Object.assign({value: o.id, label: o.name}, o);
    if (opt.group && opt.options && opt.options.length > 0) {
      opt.options = migrateOptions(o.options);
    }
    return opt;
  });
}
