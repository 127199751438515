import React, {Component} from 'react';
import Form from './form';
import request from '../request';
import {PopulationTypes, PopulationRubricTypes} from '../constants.js.erb';

export default class PopulationForm extends Component {
  constructor(props) {
    super(props);
    let {population} = props;
    this.state = {population};
    this.onChange = this.onChange.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.submit = this.submit.bind(this);
  }

  onChange(population) {
    this.setState({population});
  }

  onCancel() {
    let {population} = this.state;
     window.location = `/admin/trials/${population.trial_id}/populations/${population.id}`;
  }

  submit(e) {
    e.preventDefault();
    this.setState({submitting: true});
    let {population} = this.state;

    let url = `/admin/trials/${population.trial_id}/populations`;
    let method;


    if (population.id) {
      url += "/" + population.id;
      method = "PUT"
    } else {
      method = "POST"
    }

    let model = {};
    let fields = this.fields();
    for (let field of fields) {
      model[field.name] = population[field.name];
    }

    request(method, url, {population: model}).then(population => {
      let {onCreate} = this.props;
      if (onCreate) {
        onCreate(population);
        return;
      } else  {
        window.location = `/admin/trials/${population.trial_id}/populations/${population.id}`;
      }
      this.setState({errors: [], submitting: false, abstract_changed: false});
    }).catch(({errors}) => {
      this.setState({errors, submitting: false});
    });
  }

  fields() {
    let {therapy_trial, references} = this.props;

    return [
      {name: 'name', required: true},
      {name: 'population_type', type: 'select', collection: PopulationTypes, visible: p => therapy_trial},
      {name: 'reference_id', label: 'Reference', type: 'select', collection: references,  showLink: true, linkTemplate: '/admin/trials/{id}', visible: p => references.length > 0},
      {name: 'rubric_type', type: 'select', collection: PopulationRubricTypes, visible: p => p.reference_id},
      {name: 'only_info', type: 'bool',  visible: p => p.reference_id}
    ]
  }

  render() {
    let {population, submitting, errors} = this.state;
    let onCancel = population.id ? this.onCancel : null;

    return (
      <div>
        <Form id="populations_form" model_name="Population" prefix="population" fields={this.fields()} model={population} errors={errors} submitting={submitting} onChange={this.onChange} onSubmit={this.submit} onCancel={onCancel} />
      </div>
    );
  }
}
