import React, {Component} from 'react';

const MAX_LENGTH = 200;

export default class CollapsableText extends Component {
  constructor(props) {
    super(props);
    let maxLength = props.maxLength || MAX_LENGTH;
    this.state = {maxLength};
  }

  toggleShowMore() {
    let show_more = !this.state.show_more;
    this.setState({show_more});
  }

  renderText() {
    let {children} = this.props;
    let {show_more, maxLength} = this.state;
    if (show_more || children.length < maxLength) return children;
    return children.substring(0, maxLength);
  }

  renderShowMoreButton() {
    let {children} = this.props;
    let {maxLength} = this.state;
    if (children.length < maxLength) return null;

    let {show_more} = this.state;
    if (show_more) {
      return <button className="btn btn-link collapsable_text_show_more" onClick={e => this.toggleShowMore()}>Show Less</button>
    } else {
      return <button className="btn btn-link collapsable_text_show_more" onClick={e => this.toggleShowMore()} title="Show More">... Show More</button>
    }
  }

  render() {
    let {text, className, id} = this.props;

    return (
      <div className={className} id={id}>
        {this.renderText()}
        {this.renderShowMoreButton()}
      </div>
    );
  }
}
