import React, {Component} from 'react';
import Form from './form';
import Loading from './loading';
import request from '../request';
import {TrialSearchKey} from '../constants.js.erb';
import * as Inflector from 'inflected';

export default class DebugSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {search: {}};
    this.search = this.search.bind(this);
    this.onChange = this.onChange.bind(this);
  }


  search() {
    this.setState({searching: true, results: []});
    let {saved_search_id} = this.props;
    let url = `/admin/search/saved_searches/${saved_search_id}/debug`;
    let {trials} = this.state.search;
    let trial_identifiers = trials.split(',');
    let promises = trial_identifiers.map(identifier =>
      request('PUT', url, {identifier}).then(result => {
        result.identifier = identifier;
        let {results} = this.state;
        results.push(result);
        this.setState({results});
      }).catch(result => {
        result.identifier = identifier;
        let {results} = this.state;
        results.push(result);
        this.setState({results});
      })
    );


    Promise.all(promises).then(() => {
      this.setState({searching: false});
    });
  }

  onChange(search) {
    this.setState({search, result: null});
  }

  renderForm() {
    let {search} = this.state;
    let fields = [
      {name: 'trials', label: 'Trials or Therapies separated by comma', type: 'text', required: true},
    ]

    return <Form fields={fields} model={search} submitLabel="Search" onSubmit={this.search} onChange={this.onChange}/>
  }


  renderError(result) {
    console.log(result);
    return (
      <div>
        <h3>{result.identifier}</h3>
        <h4>ERROR</h4>
        <p>{result.message}</p>
      </div>
    )

  }

  renderResultFound(result) {
    let link = `/admin/trials/${result.trial_id}`;
    return (
      <div>
        <h3><a href={link} target="_blank">{result.identifier}</a></h3>
        <h4>The trial is included in the search results</h4>
      </div>
    )
  }

  renderFilterCondition(condition) {
    let label = Inflector.titleize(condition.type);
    return (
      <div>
        <b>Filter:</b> {label}<br/>
        <b>Populations in:</b> {condition.input_populations_count} <b>Populations out:</b> {condition.out_populations_count}
      </div>
    )
  }

  renderQueryCondition(condition) {
    let label = Inflector.titleize(condition.type);
    return (
      <div>
        <b>Query:</b> {label}
      </div>
    )
  }
  renderCondition(condition) {
    let key = `${condition.type}::${condition.source}`;
    let body = condition.source === 'filter' ? this.renderFilterCondition(condition) : this.renderQueryCondition(condition);
    return (
      <div key={key}>
        {body}
      </div>
    )
  }

  renderResult(result) {
    if (result.error) return this.renderError(result);
    if (result.found === true) return this.renderResultFound(result);
    let link = `/admin/trials/${result.trial_id}`;
    return (
      <div>
        <h3><a href={link} target="_blank">{result.identifier}</a></h3>
        <h4>The trial is <b>NOT INCLUDED</b> in the search results.</h4>
        {result.conditions.map(c => this.renderCondition(c))}
      </div>
    )
  }

  renderResults() {
    let {results} = this.state;
    if (!results || results.length === 0) return null;
    return results.map((result, index) => {
      return (
        <div key={index}>
          {this.renderResult(result)}
          <hr />
        </div>
      );
    });
  }


  renderSearching() {
    let {searching} = this.state;
    if (searching) {
      return <Loading />
    } else {
      return null;
    }
  }
  render() {
    return (
      <div>
        {this.renderForm()}
        {this.renderResults()}
        {this.renderSearching()}
      </div>
    );
  }
}
