import React, {Component} from 'react';
import Form from './form';
import Loading from './loading';
import request from '../request';
import joinReact from './join_react';
import downloadBlob from '../download_blob';
const RETRIVE_RUN_TIMEOUT = 2000;

export default class OpenaiPlayground extends Component {
  constructor(props) {
    super(props);
    this.state = {data: defaultData(), messages: []};
    this.onChange = this.onChange.bind(this);
    this.submit = this.submit.bind(this);
    this.onChangeFiles = this.onChangeFiles.bind(this);
    this.retriveRun = this.retriveRun.bind(this);
    this.onChangeMessage = this.onChangeMessage.bind(this);
  }

  componentDidMount() {
    this.createSession();
  }

  createSession() {
    let url = '/admin/openai_playground/create_session';
    request('POST', url).then(({thread, assistants}) => {
      this.setState({thread, assistants});
    });
  }

  reset() {
    let data = defaultData();
    this.setState({data, messages: [], thread: null, assistants: null}, e => {
      this.createSession();
    });
  }

  submit() {
    let {data, thread, messages, selected_file_names} = this.state;
    messages.push({id: generateUUID(), user: true, file_ids: data.file_ids, selected_file_names, content: [{text: {value: data.message}}]})

    this.setState({messages, selected_file_names: undefined, loading: true});
    data.thread_id = thread.id;

    let url = '/admin/openai_playground'
    request('POST', url, {data}).then(run => {
      data = {...data, ...defaultData()}
      this.setState({run, data}, () => this.scheduleRetriveRun());

    }).catch(error => {
      let messages = [{id: 'error', content: [{text: {value: error.message}}]}]
      this.setState({messages, loading: false});
      alert(error.message);
    });
  }

  scheduleRetriveRun() {
    setTimeout(this.retriveRun, RETRIVE_RUN_TIMEOUT);
  }

  retriveRun() {
    let {thread, run} = this.state;
    let data = {thread_id: thread.id, run_id: run.id};
    let url = '/admin/openai_playground/retrive_run'
    request('POST', url, {data}).then(message => {
      if (message.status === 'completed') {
        this.retriveMessages();
      } else {
        this.scheduleRetriveRun();
      }
    });
  }


  retriveMessages() {
    this.setState({loading: true});
    let {thread, run, messages} = this.state;
    let data = {thread_id: thread.id, run_id: run.id};
    let url = '/admin/openai_playground/retrive_messages'
    request('POST', url, {data}).then(message => {
      for (let message of message.data) {
        if (messages.findIndex(r => r.id === message.id) === -1) {
          messages.push(message);
        }
      }
      this.setState({messages, loading: false});
    });

  }

  onChange(data) {
    this.setState({data});
  }

  onChangeMessage({message}) {
    let {data} = this.state;
    data.message = message;
    this.setState({data});
  }

  // We include the file names to show them on the message list
  onChangeFiles(file_ids, selected_file_names) {
    let {data} = this.state;
    data.file_ids = file_ids;
    this.setState({data, selected_file_names});
  }

  renderSetupForm() {
    let {assistants, data, messages} = this.state;
    if (!assistants) return <Loading />

    let fields = [
      {name: 'assistant_id', type: 'select', collection: assistants, required: true},
    ];

    return <Form fields={fields} model={data} onChange={this.onChange} />
  }


  renderMessageInput() {
    let {loading, data, messages} = this.state;
    if (loading) return null;

    let fields = [
      {name: 'message', type: 'text', requited: true}
    ];

    return <Form fields={fields} model={data} onChange={this.onChangeMessage} onSubmit={this.submit} submitLabel="Submit" />
  }



  renderMessages() {
    let {messages} = this.state;
    if (!messages || messages.length === 0) return null;
    let get_more_btn;

    if (!messages[messages.length -1].user) {
      get_more_btn = <button className="btn btn-default" onClick={e => this.retriveMessages()}>Get more messages</button>;
    }
    return (
      <div>
        {messages.map(message => {
          let buffer = "";
          for (let content of message.content) {
            buffer = buffer + "\n" + content.text.value;
          }
          let agent_el, files_el;
          let className = ['message'];
          if (message.user) {
            className.push('user-message');
            agent_el = <span className="badge">User</span>
            if (message.file_ids.length > 0) {
              let files = message.selected_file_names.map(n => <span>{n}</span>);
              files_el = <p>Files: {joinReact(files, ',')}</p>
            }
          }
          return (
            <div key={message.id} className={className.join(' ')}>
              {agent_el}
              <pre className="formatted">{buffer}</pre>
              {files_el}
            </div>
          );
        })}

        {get_more_btn}
      </div>
    )
  }

  renderLoading() {
    let {loading} = this.state;
    if (!loading) return null;
    return <Loading />;
  }

  render() {
    let {data, assistants} = this.state;
    if (!data.assistant_id) return this.renderSetupForm();
    let assistant = assistants.find(a => a.id === data.assistant_id);
    return (
      <div id="openai-playground" className="row">
        <div className="col-md-9">
          <h3>
            Assistant: {assistant.name}
            <small><button onClick={e => this.reset()} className="btn btn-sm">Reset</button></small>
          </h3>
          {this.renderMessages()}
          {this.renderLoading()}
          {this.renderMessageInput()}
        </div>
        <div className="col-md-3">
          <Files file_ids={data.file_ids} onChange={this.onChangeFiles} />
        </div>

      </div>
    );
  }
}

const SUPPORTED_FILE_TYPES = ['doc', 'docx', 'json', 'md', 'pdf', 'pptx', 'tex', 'txt'];
const SUPPORTED_MIME_TYPES = ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/json', 'text/markdown', 'application/pdf', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'text/x-tex', 'text/plain'];

class Files extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.fetchFiles();
  }

  fetchFiles() {
    this.setState({loading: true});
    let url = '/admin//openai_playground/list_files';
    request('GET', url).then(files => {
      this.setState({files, loading: false});
    });
  }

  submitFile(e) {
    e.preventDefault();
    let {file} = this.state;
    if (!file) return;
    this.setState({uploading: true});

    let url = '/admin//openai_playground/upload_file';
    request('POST', url, {file}, {multipart: true, root: 'openai_playground'}).then(file => {
      let {files} = this.state;
      files.push(file)
      this.setState({files, uploading: false});
    });
  }

  downloadFile(file) {
    let url = '/admin//openai_playground/download_file';
    request('POST', url, {file_id: file.id}, {blob: true}).then(blob => {
      downloadBlob(blob, file.filename);
    });
  }


  deleteFile(file) {
    if (!confirm('Are you sure?')) return;
    let url = '/admin//openai_playground/delete_file';
    request('POST', url, {file_id: file.id}).then(response => {
      let {files} = this.state;
      files = files.filter(f => f.id !== file.id);
      this.setState({files});
    });
  }

  selectFile(e) {
    let file = e.target.files[0];
    this.setState({file});
  }

  toggleFile(file) {
    let {file_ids, onChange} = this.props;
    let {files} = this.state;
    let index = file_ids.indexOf(file.id);
    if (index === -1) {
      file_ids.push(file.id);
    } else {
      file_ids.splice(index, 1);
    }
    let file_names = file_ids.map(id => files.find(f => f.id === id).filename);
    onChange(file_ids, file_names);
  }

  renderUploadFile() {
    let {file, uploading} = this.state;
    if (uploading) return <Loading />
    let accept = SUPPORTED_MIME_TYPES.join(', ');

    return (
      <form onSubmit={e => this.submitFile(e)}>
        <input type="file" onChange={e => this.selectFile(e)} accept={accept} />
        <input type="submit" className="btn btn-default" disabled={!file} />
      </form>
    )
  }

  render() {
    let {files, loading} = this.state;
    let {file_ids} = this.props;
    if (!files || loading) return <Loading />
    window.files = files;
    return (
      <div>
        <button className="btn btn-default btn-xm pull-right" onClick={e => this.fetchFiles()}>Refresh</button>
        <h3>Files</h3>
        {files.map(file => {
          let download_btn;
          let checked = file_ids.indexOf(file.id) !== -1;
          if (file.purpose  === "assistants_output") {
            download_btn = <button className="btn btn-sm" onClick={e => this.downloadFile(file)}><span className="fa fa-download"></span></button>
          }
          return (
            <div key={file.id}>
              <label>
                <input type="checkbox" checked={checked} value={checked} onChange={e => this.toggleFile(file)} />
                {file.filename}
                <button className="btn btn-sm" onClick={e => this.deleteFile(file)}><span className="fa fa-trash"></span></button>
                {download_btn}
              </label>
            </div>
          )
        })}
        <h3>Upload File</h3>
        {this.renderUploadFile()}
      </div>
    )
  }
}

function defaultData() {
  return {file_ids: [], message: ''};
}
