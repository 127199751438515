import React, {Component} from 'react';
import Loading from '../loading';
import joinReact from '../join_react';
import GroupsInformation from '../groups_information';
import GeolocationInformation from '../geolocation_information';
import StarsRatingField from '../stars_rating_field';
import ReferenceStarsRating from '../reference_stars_rating';
import Form from '../form';
import RecordNotesWidget from '../record_notes_widget';
import CollapsableText from '../collapsable_text';
import {ReferencePopulationRatingTypes} from '../../constants.js.erb';

import request from '../../request';

export default class SearchResult extends Component {
  constructor(props) {
    super(props);

    let in_collections = findCollections(props);
    this.state = {in_collections, show_others: false};
  }

  static getDerivedStateFromProps(props, state) {
    state.in_collections = findCollections(props);
    return state;
  }

  addToCollection(collection) {
    let {trial, patient, addTrialToCollection} = this.props;
    let url = "/admin/patients/" + patient.id + "/collections/" + collection.id + "/trials"
    request('POST', url, {trial_id: trial.id}).then(({success, collection_trial}) => {
      let {in_collections} = this.state;
      in_collections.push(collection);
      this.setState({in_collections});
      addTrialToCollection(collection_trial)
    });
  }

  newCollection() {
    this.setState({show_new_collection_form: true});
  }

  closeNewCollection() {
    this.setState({show_new_collection_form: false});
  }

  onCollectionAdded(collection) {
    let {collections, onCollectionsChange} = this.props;
    collections.push(collection);
    onCollectionsChange(collections);
    this.addToCollection(collection);
  }

  toogleSelected() {
    let {trial, selectCallback} = this.props;
    selectCallback && selectCallback(trial);
  }

  toggleOtherPopulationsVisibility(e) {
    let show_others = !this.state.show_others;
    this.setState({show_others});
  }

  toggleReferencesVisibility() {
    let show_references = !this.state.show_references;
    this.setState({show_references})
  }

  renderNewCollectionModal() {
    let {patient} = this.props;
    let {show_new_collection_form} = this.state;
    if (!show_new_collection_form) return null;
    return <CollectionModal patient={patient} onClose={e => this.closeNewCollection()} onSubmit={c => this.onCollectionAdded(c)} />
  }

  renderNCT(trial) {
    let identifier, status, overall_status;

    // Set the link data
    if (trial.isTrial) {
      identifier = <a href={trial.study_url} target="_blank">{trial.identifier}</a>
    } else {
      identifier = trial.identifier;
    }

    if (trial.status_humanized) {
      status = "[" + trial.status_humanized + "]"
    }

    if (trial.isTrial) {
      let label = trial.overall_status_humanized || "No Status"
      overall_status = "(" + label + ")"
    }

    return(
      <span>
        {identifier} {overall_status} {status}
      </span>
    )
  }

  renderStartDate(trial) {
    let date = trial.start_date;
    let type = trial.start_date_type;

    return (
      <span>Start: {date} ({type})</span>
    );

  }

  renderOtherPopulations(populations_data) {
    if (!this.state.show_others) return null;

    let others = [];
    for (let i=1; i < populations_data.length; i++) {
      let population = populations_data[i];
      let link = linkToPopulation(population);
      let notes_icon;
      if (population.has_notes) {
        let notes_link = `/admin/trials/${population.trial_id}/populations/${population.id}#notes`;
        notes_icon = <a href={notes_link} target="_blank"><span className="glyphicon glyphicon-comment" /></a>
      }
      others.push(
        <li key={population.id}>
          <a href={link} target="_blank">{population.name}</a> {notes_icon}
        </li>
      );
    }

    return (
      <div className="other_populations">
        <ul>
          {others}
      </ul>
      </div>
    );
  }

  renderPopulationsInfo(populations) {
    if (populations.length < 2 || !this.props.admin_mode) return null;

    let link_copy = this.state.show_others ? "Close" : "Show"

    return (
      <div className="populations_info">
        <div className="copy">
          {populations.length -1} other populations for this therapy
          <button className="show_populations_button pull-right" onClick={e => this.toggleOtherPopulationsVisibility(e)}>{link_copy}</button>
          <div className="clearfix"></div>
        </div>
        {this.renderOtherPopulations(populations)}
      </div>
    )
  }

  renderIcons(trial) {
    let combination = trial.included_combination;
    let random = trial.arm_randomized;
    let placebo = trial.includes_placebo;

    let ref_icon;
    if (trial.references_count > 0) {
      ref_icon = <button className="btn btn-link" onClick={e => this.toggleReferencesVisibility()}><i className="fa fa-book green" title="References" /></button>
    } else if (trial.trial_references_changelog.length > 0) {
      ref_icon = <button className="btn btn-link" onClick={e => this.toggleReferencesVisibility()}><i className="fa fa-book red" title="References" /></button>
    } else {
      ref_icon = <i className="fa fa-book no-reference" title="References" />
    }

    return (
      <span className="trial_icons">
        {ref_icon}
        <i className="fa fa-plus-circle" data-enabled={combination} title="Combination" />
        <i className="fa fa-random" data-enabled={random} title="Randomization" />
        <i className="fa fa-exclamation-circle" data-enabled={placebo} title="Placebo" />
      </span>
    )
  }


  renderReferencesSummary() {
    let {trial, reference_population_ids} = this.props;
    let {show_references} = this.state;

    if (trial.reference_populations_data.length === 0) return null;

    let show_button_label = show_references ? 'Hide' : 'Show';
    let details_open_class = show_references ? 'references_rating_summary ref_details_open' : 'references_rating_summary';

    let scored_populations = trial.reference_populations_data.filter(p => p.data !== null && !p.only_info);
    let matched_populations = scored_populations.filter(p => reference_population_ids.indexOf(p.id) !== -1);
    let unmatched_populations = scored_populations.filter(p => reference_population_ids.indexOf(p.id) === -1);

    let matched_efficacy_ceiling = matched_populations.reduce(efficacyCeiling, null);
    let unmatched_efficacy_ceiling = unmatched_populations.reduce(efficacyCeiling, null);
    let side_effect_floor = scored_populations.reduce(sideEffectFloor, null);

    return (
      <div className="reference_rating_wrapper">
        <div className={details_open_class}>
          <div className="ref_summary_box">
            <div className="ref_summary_header">
              <span className="ref_pop_label match_label">{matched_populations.length} Matching</span>
              Highest Efficacy
            </div>
            <StarsRatingField value={matched_efficacy_ceiling} starsCount={5} extraOptions={ReferenceStarsRating.EXTRA_OPTIONS} valueComponentOnly={true}/>
          </div>
          <div className="ref_summary_box">
            <div className="ref_summary_header">
              <span className="ref_pop_label related_label">{unmatched_populations.length} Related</span>
              Highest Efficacy
            </div>
            <StarsRatingField value={unmatched_efficacy_ceiling} starsCount={5} extraOptions={ReferenceStarsRating.EXTRA_OPTIONS} valueComponentOnly={true}/>
          </div>
          <div className="ref_summary_box">
            <div className="ref_summary_header">
              <span>Lowest Safety:</span>
            </div>
              <StarsRatingField value={side_effect_floor} starsCount={5} extraOptions={ReferenceStarsRating.EXTRA_OPTIONS} valueComponentOnly={true}/>
          </div>
        </div>
        <a className="ref_see_more_button" onClick={e => this.toggleReferencesVisibility()}>{show_button_label} Details</a>
      </div>
    );
  }

  renderReferencesContent() {
    let {trial, reference_population_ids} = this.props;

    let changelog;

    if (trial.trial_references_changelog.length > 0) {
      let last = trial.trial_references_changelog[trial.trial_references_changelog.length -1]
      let date = moment(last.date).format('LLL')
      changelog = <p className="changelog-item">On {date}  checked for references and found no updates.</p>
    }

    return (
      <div className="search_reference_display">
        <div>
          <TrialReferences trial={trial} reference_population_ids={reference_population_ids} />
        </div>
        {changelog}
      </div>
    )
  }

  renderReferenceStarsRating(reference) {
    let {admin_mode} = this.props;
    if (!admin_mode) return null;
    return <ReferenceStarsRating reference={reference} />
  }

  renderReferences(trial) {
    let {show_references} = this.state;
    if (!show_references) return null;

    return (
      <div className="references_dropdown">
        <h4>References</h4>
        {this.renderReferencesContent(trial)}
        <button onClick={e => this.setState({show_references: false})} className="pull-right btn btn-sm btn-warning">Close</button>
        <div className="clearfix"></div>
      </div>
    );
  }


  renderGenes(population) {
    if (!population.genes_markers_information_present) return null;
    return (
      <div className="genes_markers">
        <h4>Genes &amp; Diagnostics:</h4>
        {population.genes_markers_information.map(i => {
          return (
            <ul key={i.section}>
              {i.content.map(({title, body}, index) => {
                return(
                  <li key={index}>
                    <strong>{title}:</strong> {body}
                    </li>
                )
              })}
            </ul>
          )})
        }
      </div>
    )
  }

  renderSelectedIcon() {
    let {selected} = this.props;
    let icon = selected ? 'glyphicon-star' : 'glyphicon-star-empty';
    let btnStyle = selected ? 'btn btn-success pull-right' : 'btn btn-default pull-right';
    let className = 'glyphicon ' + icon;
    return (
      <button className={btnStyle} onClick={e => this.toogleSelected()}><i className={className} /></button>
    )
  }


  renderPopulationName(population) {
    if (this.props.admin_mode) {
      let link = linkToPopulation(population);
      return <a href={link} target="_blank">{population.name}</a>;
    } else {
      return population.name;
    }
  }


  renderCollectionsButton() {
    let {collections, patient} = this.props;
    if (!patient) return null;

    let {in_collections} = this.state;
    return (
      <div className="dropdown" style={{display: 'inline-block'}}>
        <button className="btn btn-default dropdown-toggle add-collection-btn" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
          Add to collection <span className="caret"></span>
        </button>

        <ul className="dropdown-menu">
          {collections.map(collection => {
            if (in_collections.findIndex(c => collection.id === c.id) === -1) {
              return <li key={collection.id}><button className="btn btn-link" onClick={e => this.addToCollection(collection)}>{collection.name}</button></li>
            } else {
              return <li key={collection.id}><span className="in-collection-span">{collection.name}</span></li>
            }
          })}

          <li role="separator" className="divider"></li>
          <li><button className="btn btn-link" onClick={e => this.newCollection()}>Create New Collection</button></li>
        </ul>
      </div>
    )
  }

  renderRecentAndConcurrentConfig(population) {
    if (!population['recent_configured?'] && !population['concurrent_configured?']) return null;

    let link_to_recent = `/admin/trials/${population.trial_id}/populations/${population.id}#recent`;
    let link_to_concurrent = `/admin/trials/${population.trial_id}/populations/${population.id}#concurrent`;

    let recent_link = <a href={link_to_recent} target="_blank">Recent</a>;
    let concurrent_link = <a href={link_to_concurrent} target="_blank">Concurrent</a>;
    let text;

    if (population['recent_configured?'] && population['concurrent_configured?']) {
      text = <span>{recent_link} and {concurrent_link}</span>
    } else if (population['recent_configured?']) {
      text = recent_link
    } else if (population['concurrent_configured?']) {
      text = concurrent_link
    }

    return (
      <div className="result_therapy_text">
        <p>This trial has {text} therapy requirements.</p>
      </div>
    )
  }



  renderButtons() {
    let {admin_mode, trial, population_data, link, trial_show} = this.props;
    if (!admin_mode) return null;


    return (
      <div className="search-action-group">
        <div className="btn-group">
          <a className="btn btn-default" href={linkToPopulation(population_data[0])} target="_blank">Population</a>
          <a className="btn btn-default" href={link} target="_blank">Trial</a>
          <a className="btn btn-default" href={trial_show} target="_blank">View</a>
          <a className="btn btn-default" href={trial_show + "/references"} target="_blank">References</a>
        </div>
        <div className="search-action-sub-group">
          {this.renderCollectionsButton()}
          <RecordNotesWidget record_type="Trial" record_id={trial.id} buttonClassName="btn btn-default" />
        </div>
      </div>
    )
  }


  renderPatientInformation() {
    let {in_collections} = this.state;
    if (in_collections.length === 0) return null;
    let {patient} = this.props;
    let links = in_collections.map(collection => {
      let url = "/admin/patients/" + patient.id + "/collections/" + collection.id;
      return <a key={collection.id} href={url}>{collection.name}</a>
    })

    return (
      <p className="current-collections">
        In these collection(s): {joinReact(links, ', ')}
      </p>
    )
  }

  renderLastLocationVerifiedAt() {
    let {trial} = this.props;
    if (!trial.last_location_verified_at) return null;
    let date = moment(trial.last_location_verified_at).format('LL');
    return <span className="label label-primary">Last trial location verificaion: {date}</span>;
  }

  render() {
    let {trial, match_label, population_data, patient, admin_mode, index, new_result, extra_locations, geolocation} = this.props;
    let population = population_data[0];
    let notes_icon;
    if (population.has_notes) {
      let notes_link = `/admin/trials/${population.trial_id}/populations/${population.id}#notes`;
      notes_icon = <a href={notes_link} target="_blank"><span className="glyphicon glyphicon-comment" /></a>
    }

    let container_class = "search_result_container";
    if (new_result) container_class += " new_result";

    return(
      <div className={container_class}>
        <div className="search_result">
          <h1 className="search_result_index">{index}</h1>
          <div className="row">
            <div className="col-md-12">
              {this.renderSelectedIcon()}
              <h4>{match_label}: {this.renderPopulationName(population)} {notes_icon}</h4>
            </div>
          </div>

          <p className="result_name">
            {trial.name}
          </p>
          <p className="search_trial_info">{this.renderIcons(trial)} &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;{this.renderNCT(trial)} &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp; {this.renderStartDate(trial)}</p>
          {this.renderReferencesSummary()}
          {this.renderReferences(trial)}

          {this.renderGenes(population)}

          <div className="search_results_group_details">
            <h4>Interventions:</h4>
            <GroupsInformation data={trial.groups_information} admin_mode={admin_mode} />
          </div>



          {this.renderButtons()}

          {this.renderPatientInformation()}
          {this.renderRecentAndConcurrentConfig(population)}
          <GeolocationInformation patient={patient} cache={geolocation}/>
          {this.renderLastLocationVerifiedAt()}
        </div>

        {this.renderPopulationsInfo(population_data)}
        {this.renderNewCollectionModal()}
      </div>
    )
  }
}

const formatDate = (date) => moment(date).format("LLL")



function findCollections({trial,  collections}) {
  if (!collections || collections.length === 0) return [];
  let in_collections = [];

  for (let collection of collections) {
    for (let collection_trial of collection.trials) {
      if (collection_trial.trial_id === trial.id) {
        in_collections.push(collection);
      }
    }
  }
  return in_collections;
}




class CollectionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {collection: {name: ''}, errors: []};
  }

  componentDidMount() {
    this.el = $(this.refs.modal_container);
    this.el.modal({show: true});
    this.el.on('hidden.bs.modal', e => {
      this.cancel(e);
    });
  }

  submit(e) {
    e.preventDefault();
    let {collection} = this.state;
    let {patient, onSubmit} = this.props;
    let url = "/admin/patients/" + patient.id + "/collections"
    request('POST', url, {collection}).then(({success, collection}) => {
      if (success) {
        this.cancel();
        onSubmit(collection);
      }
    });
  }

  onChange(collection) {
    this.setState({collection});
  }

  cancel(e) {
    this.el.modal('hide');
    let {onClose} = this.props;
    onClose();
  }

  formFields() {
    return [
      {name: 'name', required: true}
    ]
  }

  render() {
    let {collection, errors} = this.state;

    return (
      <div className="modal fade" tabIndex="-1" role="dialog" ref="modal_container">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" onClick={e => this.cancel(e)} aria-label="Close"><span aria-hidden="true">&times;</span></button>
              <h4 className="modal-title"><b>New Collection</b></h4>
            </div>

            <div className="modal-body">
              <Form model_name="Collection" prefix="collection" fields={this.formFields()} model={collection} errors={errors} onChange={r => this.onChange(r)} onSubmit={e => this.submit(e)} onCancel={e => this.cancel(e)} />
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-default" onClick={e => this.cancel(e)}>Close</button>
              <button type="submit" className="btn btn-primary" onClick={e => this.submit(e)}>Submit</button>
            </div>

          </div>
        </div>
      </div>

    );
  }
}


class TrialReferences extends Component {
  constructor(props) {
    super(props);

    // Denormalize data
    let {trial, reference_population_ids} = props;
    let records = trial.references.map(reference => {
      let populations = trial.reference_populations_data.filter(p => p.reference_id === reference.id);
      populations.forEach(p => p.matched = reference_population_ids.indexOf(p.id) !== -1);
      populations = populations.sort((a, b) => (a.matched === b.matched) ? 0 : a.matched ? -1 : 1);
      reference.populations_count = populations.length;

      return {reference, populations};
    });

    records = records.sort(compareReferenceRecords);
    this.state = {records};
  }

  renderDetails(population) {
    if (!population.data) return null;
    if (population.data.ratings && population.data.ratings.length > 0) return this.renderRatings(population);
    if (population.data.notable_information && population.data.notable_information.length > 0) return this.renderNotableInformation(population);
    return null;
  }

  renderNotableInformation(population) {
    return <p>{population.data.notable_information}</p>;
  }

  renderRatings(population) {
    if (!population.data || !population.data.ratings || population.data.ratings.length === 0) return null;
    let ratings = population.data.ratings;

    return (
      <div>
        <div className="reference_population_rating">
          <div className="rating_type"></div>
          <div className="rating_stars"><b>Efficacy</b></div>
          <div className="rating_stars"><b>Safety</b></div>

        </div>
        {ratings.map((rating, index) => {
          let rating_type = ReferencePopulationRatingTypes[rating.rating_type];
          let item_names = rating.items.map(i => i.name).join(', ');
          return (
            <div key={index} className="reference_population_rating">
              <div className="rating_type">
                <strong>{rating_type}</strong><br/>
                <span className="rating_items">{item_names}</span>
              </div>
              <div className="rating_stars">
                <StarsRatingField value={rating.efficacy_rating} starsCount={5} extraOptions={ReferenceStarsRating.EXTRA_OPTIONS} valueComponentOnly={true}/>
                {rating.efficacy_description ? <CollapsableText className="rating_description_text">{rating.efficacy_description}</CollapsableText> : ''}
              </div>
              <div className="rating_stars">
                <StarsRatingField value={rating.safety_rating} starsCount={5} extraOptions={ReferenceStarsRating.EXTRA_OPTIONS} valueComponentOnly={true}/>
                {rating.safety_description ? <CollapsableText className="rating_description_text">{rating.safety_description}</CollapsableText> :''}
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  renderReferencePopulation(population) {
    let link = `/admin/trials/${population.trial_id}/populations/${population.id}`;
    let match_el, match_type_class, only_info_el;

    if (population.matched) {
      match_el = <span className="ref_pop_label match_label">Match</span>;
      match_type_class = "reference_population_rating_container matching_pop";
    } else {
      match_el = <span className="ref_pop_label related_label">Related</span>;
      match_type_class = "reference_population_rating_container related_pop";
    }
    if (population.only_info) {
      only_info_el = <span className="ref_pop_label info_only_label" >Info Only</span>;
    }

    return (
      <div key={population.id} className={match_type_class}>
        <div className="ref_pop_header">
          {match_el} {only_info_el}
          <strong><a href={link} target="_blank">{population.name}</a></strong>
        </div>
        {this.renderDetails(population)}
      </div>
    )
  }

  renderReference(reference, populations) {
    let link = `/admin/references/${reference.id}`;
    let link_text = reference.source_link
    if (link_text && link_text.length > 60) {
      link_text = link_text.substring(0, 60) + '...';
    }

    return (
      <div key={reference.id} className="reference_rating">
        <div className="reference_rating_header">
          <h5><a href={link} target="_blank">{reference.name} ({reference.reference_year})</a></h5>
          <a href={reference.source_link} target="_blank">{link_text}</a>
        </div>
        {populations.map(p => this.renderReferencePopulation(p))}
        <hr />
      </div>
    );
  }

  render() {
    let {records} = this.state;
    return (
      <div>
        {records.map(({reference, populations}) => this.renderReference(reference, populations))}
      </div>
    )
  }
}


function linkToPopulation(population) {
  return `/admin/trials/${population.trial_id}/populations/${population.id}`;
}

function efficacyCeiling(acc, population) {
  if (!population.data || !population.data.ratings) return acc;

  for (let ratings of population.data.ratings) {
    if (ratings.efficacy_rating && (acc === null || ratings.efficacy_rating > acc)) {
      acc = ratings.efficacy_rating;
    }
  }
  return acc;
}

function sideEffectFloor(acc, population) {
  if (!population.data || !population.data.ratings) return acc;

  for (let ratings of population.data.ratings) {
    if (ratings.safety_rating && ratings.safety_rating >= 0 && (acc === null || ratings.safety_rating < acc)) {
      acc = ratings.safety_rating;
    }
  }
  return acc;
}


function compareReferenceRecords(record_a, record_b) {
  let reference_a = record_a.reference;
  let populations_a = record_a.populations;
  let reference_a_has_matching_populations = populations_a.some(p => p.matched);

  let reference_b = record_b.reference;
  let populations_b = record_b.populations;
  let reference_b_has_matching_populations = populations_b.some(p => p.matched);

  if (reference_a_has_matching_populations && !reference_b_has_matching_populations) {
    return -1;
  }

  if (!reference_a_has_matching_populations && reference_b_has_matching_populations) {
    return 1;
  }

  if (populations_a.length > populations_b.length) {
    return -1;
  }

  if (populations_a.length < populations_b.length) {
      return 1;
  }

  if (reference_a.reference_year === reference_b.reference_year) {
    return 0;
  }

  if (reference_a.reference_year === null) {
    return 1;
  }

  if (reference_b.reference_year === null) {
    return -1;
  }

  if (reference_a.reference_year < reference_b.reference_year) {
    return 11;
  }

  if (reference_a.reference_year > reference_b.reference_year) {
    return -1;
  }

  return 0
}
